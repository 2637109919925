const module_name = 'cabinet'
const singular    = 'cabinet'
const plural      = 'cabinet'

export default {
    "base_url": "",
    "module_path": `@src/modules/private/${module_name}`,
    "view": {
        "name": singular[0].toUpperCase() + singular.slice(1),
    },
    "route": {
        "path": `${plural}`,
        "name": `${singular}`
    },
    "middleware": {
        "permission": `${singular}`
    }
}
