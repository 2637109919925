const module_name = 'user'
const singular    = 'user'
const plural      = 'users'

export default {
    "base_url": "",
    "module_path": `@src/modules/user/${module_name}`,
    "view": {
        "name": singular[0].toUpperCase() + singular.slice(1),
    },
    "route": {
        "path": `user/${plural}`,
        "name": `user.${singular}`
    },
    "middleware": {
        "permission": `user-${singular}`
    }
}
