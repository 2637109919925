import auth from '@/middleware/auth';
import env from '../env'

const path = env.route.path;
const name = env.route.name;

export default [
    {
        path: `/${path}`,
        name: name,
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('../views/MainView.vue')
    },
    {
        path: `/${path}/:id`,
        name: `${name}.show`,
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('../views/ShowView.vue')
    },
    {
        path: `/${path}/search`,
        name: `${name}.search`,
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('../views/SearchMovieView.vue')
    },
    {
        path: `/${path}/my-history`,
        name: `${name}.my-history`,
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('../views/MyHistoryMovieView.vue')
    },
    {
        path: `/${path}/my-movie`,
        name: `${name}.my-movie`,
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('../views/MyLikeMovieView.vue')
    },
    {
        path: `/${path}/maxxidom-movie`,
        name: `${name}.maxxidom-movie`,
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('../views/MaxxidomMovieView.vue')
    },
    {
        path: `/${path}/person/:id`,
        name: `${name}.person`,
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('../views/PersonView.vue')
    },
];
