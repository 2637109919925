import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'


import './assets/scss/main.scss'
import options from "./options"
import {i18n} from './plugins/i18n'


require('./store/subscriber')

axios.defaults.withCredentials = false;
axios.defaults.baseURL = options.default_url + '/api/';

store.dispatch('attempt', localStorage.getItem('token')).then(() => {
    createApp(App).use(store).use(router).use(i18n).mount('#app')
})
