import {i18n} from "@/plugins/i18n";

export default {
  set(language: string) {
    import(`@/langs/${language}/index`).then((ms) => {
      i18n.global.setLocaleMessage(language, ms.default);
      i18n.global.locale = language;
    });
  }
}
