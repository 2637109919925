<template>
  <component :is="layout">
    <router-view/>
  </component>
</template>

<script>

import EmptyLayout from "./layouts/EmptyLayout"
import MainLayout from "./layouts/MainLayout"
import AuthLayout from "./layouts/AuthLayout"
import PublicLayout from "./layouts/PublicLayout"


export default {
  components: {
    EmptyLayout, MainLayout, AuthLayout, PublicLayout
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || 'empty') + '-layout';
    },
    theme() {
      return this.$store.getters.is_theme_dark ? 'mx-theme-dark' : 'mx-theme-light'
    }
  },
  mounted() {
    document.body.classList.add(this.theme)
  },
  unmounted() {
    document.body.classList.remove(this.theme)
  }
}
</script>
