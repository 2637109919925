<template>
  <header class="mx-header">
    <template v-if="!is_auth">
      <router-link  to="/login">{{ $t('auth.login.title') }}</router-link>
    </template>
    <template v-else>
      <router-link  :to="{name: 'dashboard'}">{{ $t('cabinet.link.dashboard') }}</router-link>
      <button @click="logout">{{ $t('auth.logout.title') }}</button>
      <span style="display: block; margin-top: 10px;">{{ $t('g.greeting') }}, {{ username }}</span>
    </template>
  </header>

  <main class="mx-main">
    <article class="mx-article">
      <router-view/>
    </article>
  </main>
  <footer class="mx-footer">Footer</footer>
</template>

<script>
import language from "@/scripts/mx_language";

export default {
  data() {
    return {
      theme: false
    }
  },
  created() {
    this.theme = this.$store.getters.is_theme_dark
  },
  computed: {
    is_auth() {
      return this.$store.getters.authenticated
    },
    username() {
      return this.$store.getters.user.first_name + ' ' + this.$store.getters.user.last_name
    }
  },
  watch: {
    theme() {
      this.$store.dispatch('changeTheme', this.theme)
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('signOut')
    },
    setLanguage(lang) {
      language.set(lang)
    }
  }
}
</script>

<style lang="scss" scoped>
.mx-header {
  display: flex;
  width: 100%;
  padding: 20px;
  background: var(--mx-primary)
}
.mx-main {
  display: flex;
  flex: 1 1 auto;
  background: #fabfbf;
}
</style>
