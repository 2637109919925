const module_name = 'movie'
const singular    = 'movie'
const plural      = 'movies'

export default {
    "base_url": "",
    "module_path": `@src/modules/movies/${module_name}`,
    "view": {
        "name": singular[0].toUpperCase() + singular.slice(1),
    },
    "route": {
        "path": `${plural}`,
        "name": `${singular}`
    },
    "middleware": {
        "permission": `${singular}`
    }
}
