import { createStore } from 'vuex'

import auth from '@/modules/user/auth/store/store'
import user from '@/modules/user/user/store/store'
import pb from '@/modules/public/public/store/store'
import cabinet from '@/modules/private/cabinet/store/store'
import movie from '@/modules/movies/movie/store/store'

export default createStore({
  state: {
    theme_dark: true,
  },
  getters: {
    is_theme_dark(state) {
      return state.theme_dark
    }
  },
  mutations: {
    CHANGE_THEME(state, status) {
      state.theme_dark = status
      document.body.classList.add(status ? 'mx-theme-dark' : 'mx-theme-light')
      document.body.classList.remove(!status ? 'mx-theme-dark' : 'mx-theme-light')
    }
  },
  actions: {
    changeTheme({commit}, status) {
      commit('CHANGE_THEME', status)
    },
  },
  modules: {
    auth,
    user,
    pb,
    cabinet,
    movie,
  }
})
