import { http } from "./http_service";

export function currentYearMovies(option) {
    const op = option.category ? `${option.page}/${option.category}`: option.page
    return http().get(`/movie/all/${op}`);
}

export function getMovieByKinopoiskId(id) {
    return http().get(`/movie/${id}`);
}

export function getPersonById(id) {
    return http().get(`/person/${id}`);
}

export function getMovieSearch(search) {
    return http().get(`/movie/search?search=${search}`);
}

export function getMyHistoryMovie(page) {
    return http().get(`/movie/my-history-movie?page=${page}`);
}

export function detachMyHistoryMovie(id) {
    return http().get(`/movie/my-history-movie/${id}/detach`);
}

export function addMyHistoryMovie(id) {
    return http().get(`/movie/my-history-movie/${id}/add`);
}

export function getMyLikeMovies(page) {
    return http().get(`/movie/my-like-movie?page=${page}`);
}

export function getMaxxidomMovies(page) {
    return http().get(`/movie/maxxidom-movie?page=${page}`);
}


export function getMyLikeMovieIDS() {
    return http().get(`/movie/my-like-movie-ids`);
}

export function detachMyLikeMovie(id) {
    return http().get(`/movie/my-like-movie/${id}/detach`);
}

export function addMyLikeMovie(id) {
    return http().get(`/movie/my-like-movie/${id}/add`);
}