import * as Service from '../service/service'

export default {
    namespace: true,
    state: {
        user: {},
    },
    getters: {
        user(state) {
            return state.user
        }
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user
        }
    },
    actions: {
        async userEdit({commit}, data) {
            await Service.userUpdate(data, data.user_id).then(response => {
                commit('SET_USER', response.data.data)
                commit('SET_LANGUAGE', response.data.data.language)
            })
        }
    }
}
