import guest from '@/middleware/guest'

export default [
    {
        path: `/login`,
        name: 'login',
        meta: {
            layout: 'auth',
            middleware: [guest]
        },
        component: () => import('../views/LoginView.vue')
    },
]
