export default {
    "logout": {
        "title": "Выйти",
        "btn": "Выйти"
    },
    "login": {
        "title": "Войти",
        "btn": "Войти",
        "label": {
            "email": "Емайл",
            "password": "Пароль"
        },
        "required": {
            "email": "Емайл Обязателен",
            "password": "Пароль Обязателен",
        },
        "error": "Емайл или Пароль неправельный!"
    },
    "password": {
        "forgot": {
            "title": "Забыл пароль?",
            "subtitle": "Ничего страшного мы прищлем вам новый",
            "btn": {
                "forgot": "Забыл пароль?",
                "send": "Востоновить пароль",
                "login": "Вернутся к авторизации",
            },
            "email": "Емайл",
            "text": {
                "title": "Мыотослали вам письмо с ссылкой для востоновления пароля",
                "description": "Все что ва м нужно вы найдете в этом письме."
            },
        },
        "reset": {
            "title": "збросить пароль",
            "token": "Токен",
            "new_password": "Новый пассворд",
            "new_password_confirmation": "Подтвердить новый пасворд",
            "btn": {
                "reset": "Сбросить",
                "login": "Вернутся к авторизации",
            },
            "error": "Токен или Пароль неправельный!"
        },
    },
}
