// npm install vue-i18n@9
import { createI18n } from 'vue-i18n'

import options from "@/options";

import de from '../langs/de/index';
import en from '../langs/en/index';
import ru from '../langs/ru/index';


const languages = [
  {local: 'de', obj: de},
  {local: 'en', obj: en},
  {local: 'ru', obj: ru},
]

export const i18n = createI18n({
  locale: options.language,
  fallbackLocale: options.fallbackLocale
})

for (let i = 0; i < languages.length; i++) {
  i18n.global.setLocaleMessage(languages[i].local, languages[i].obj)
}

