import * as Service from '../service/service'
import Movie from "@/modules/movies/types/movie";


export default {
    namespace: true,
    state: {
        current_year_movies: {
            data: [],
            paginate: {}
        },
        current_movie: {},
        search_movies: {
            data: [],
            paginate: {}
        },
        my_history_movie: {
            data: [],
            paginate: {}
        },
        my_like_movie: {
            data: [],
            paginate: {}
        },
        maxxidom_movie: {
            data: [],
            paginate: {}
        },
        my_like_movie_ids: [],
        person: []
    },
    getters: {
        getCurrentYearMovies(state) {
            return state.current_year_movies
        },
        getCurrentMovie(state) {
            return state.current_movie
        },
        getSearchMovies(state) {
            return state.search_movies
        },
        getMyHistoryMovies(state) {
            return state.my_history_movie
        },
        getMyLikeMovies(state) {
            return state.my_like_movie
        },
        getMaxxidomMovies(state) {
            return state.maxxidom_movie
        },
        getMyLikeMovieIDS(state) {
            return state.my_like_movie_ids
        },
        getPerson(state) {
            return state.person
        },
    },
    mutations: {
        SET_CURRENT_YEAR_MOVIES(state, movies) {
            state.current_year_movies.data = state.current_year_movies.data.concat(movies.data)
            state.current_year_movies.paginate = movies.paginate
        },
        SET_CURRENT_MOVIE(state, movie: Movie) {
            state.current_movie = movie
        },
        SET_SEARCH_MOVIES(state, movies) {
            state.search_movies = movies
        },
        SET_MY_HISTORY_MOVIES(state, movies) {
            state.my_history_movie.data = state.my_history_movie.data.concat(movies.data)
            state.my_history_movie.paginate.page = movies.current_page
            state.my_history_movie.paginate.total = movies.total
            state.my_history_movie.paginate.pages = movies.last_page
        },
        SET_MY_HISTORY_MOVIE_DETACH(state, movie_id) {
            state.my_history_movie.data = state.my_history_movie.data.filter(movie => movie.id !== movie_id)
        },
        SET_MY_HISTORY_MOVIE_ADD(state, current_movie) {
            state.my_history_movie.data.unshift(current_movie)
        },
        SET_MY_LIKE_MOVIES(state, movies) {
            if (movies.current_page <= 1) {
                state.my_like_movie.data = movies.data
            } else {
                state.my_like_movie.data = state.my_like_movie.data.concat(movies.data)
            }

            state.my_like_movie.paginate.page = movies.current_page
            state.my_like_movie.paginate.total = movies.total
            state.my_like_movie.paginate.pages = movies.last_page
        },
        SET_MAXXIDOM_MOVIES(state, movies) {
            if (movies.current_page <= 1) {
                state.maxxidom_movie.data = movies.data
            } else {
                state.maxxidom_movie.data = state.maxxidom_movie.data.concat(movies.data)
            }

            state.maxxidom_movie.paginate.page = movies.current_page
            state.maxxidom_movie.paginate.total = movies.total
            state.maxxidom_movie.paginate.pages = movies.last_page
        },
        SET_MY_LIKE_MOVIE_IDS(state, ids) {
            state.my_like_movie_ids = ids
        },
        SET_MY_LIKE_MOVIE_DETACH(state, movie_id) {
            state.my_like_movie.data = state.my_like_movie.data.filter(movie => movie.id !== movie_id)
        },
        SET_MY_LIKE_MOVIE_ADD(state, current_movie) {
            state.my_like_movie.data.unshift(current_movie)
        },
        SET_PERSON(state, person) {
            state.person = person
        }
    },
    actions: {
        async currentYearMovies({commit}, option) {
            const response = await Service.currentYearMovies(option)
            commit('SET_CURRENT_YEAR_MOVIES', response.data)
            return response.data
        },

        async getMovieByKinopoiskId({commit}, kinopoisk_id) {
            const response = await Service.getMovieByKinopoiskId(kinopoisk_id)
            commit('SET_CURRENT_MOVIE', response.data.data)
            return response
        },

        async getPersonById({commit, state}, person_id) {
            // console.log('DDD', state.person, state.person.length);
            // if (state.person.length !== 0) {
            //     return null
            // }
            // console.log('SSS');
            const response = await Service.getPersonById(person_id)
            commit('SET_PERSON', response.data.data)
            return response
        },

        async searchMovie({commit}, search) {
         const response = await Service.getMovieSearch(search)
         commit('SET_SEARCH_MOVIES', response.data)
         return response.data
        },

        /****  My History Movie ****/
        async getMyHistoryMovie({commit}, page) {
            const response = await Service.getMyHistoryMovie(page)
            commit('SET_MY_HISTORY_MOVIES', response.data)
            return response.data
        },

        async detachMyHistoryMovie({commit}, id) {
            await Service.detachMyHistoryMovie(id)
            commit('SET_MY_HISTORY_MOVIE_DETACH', id)
        },

        async addMyHistoryMovie({commit, state}, id) {
            await Service.addMyHistoryMovie(id)
            commit('SET_MY_HISTORY_MOVIE_ADD', state.current_movie)
        },

        /****  My Like Movie ****/
        async getMyLikeMovies({commit}, page) {
            const response = await Service.getMyLikeMovies(page)
            commit('SET_MY_LIKE_MOVIES', response.data)
            return response.data
        },

        async getMyLikeMovieIDS({commit}) {
            const response = await Service.getMyLikeMovieIDS()
            commit('SET_MY_LIKE_MOVIE_IDS', response.data)
            return response.data
        },

        async detachMyLikeMovie({commit}, id) {
            await Service.detachMyLikeMovie(id)
            commit('SET_MY_LIKE_MOVIE_DETACH', id)
        },

        async addMyLikeMovie({commit, state}, id) {
            await Service.addMyLikeMovie(id)
            commit('SET_MY_LIKE_MOVIE_ADD', state.current_movie)
        },


        /****  Maxxidom Movie ****/
        async getMaxxidomMovies({commit}, page) {
            const response = await Service.getMaxxidomMovies(page)
            commit('SET_MAXXIDOM_MOVIES', response.data)
            return response.data
        },
    }
}
