import auth from '@/middleware/auth';
import env from '../env'

const path = env.route.path;
const name = env.route.name;

export default [
    {
        path: `/${path}`,
        name: name,
        meta: {
            layout: 'main',
            middleware: [auth]
        },
        component: () => import('../views/MainView.vue')
    },
];
