import {createRouter, createWebHistory} from 'vue-router'
import gust from '@/middleware/guest';

import auth from '@/modules/user/auth/router/index'
import user from '@/modules/user/user/router/index'
import cabinet from '@/modules/private/cabinet/router/index'
import movie from "@/modules/movies/movie/router/index"

const routes = [
    ...auth,
    ...user,
    ...cabinet,
    ...movie,
    {
        path: '/',
        name: 'home',
        meta: {
            layout: 'main',
            middleware: [gust]
        },
        redirect: "/login",
    },
    {
        path: '/*',
        meta: {
            layout: 'empty'
        },
        component: () => import('@/views/NotFoundView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router

function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    if (!subsequentMiddleware) {
        return context.next;
    }

    return (...parameter) => {
        context.next(...parameter);
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({...context, next: nextMiddleware})
    }
}

router.beforeEach((to, from, next) => {
    if (to.meta.middleware) {
        const context = {from, next, router, to};
        const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
        const nextMiddleware = nextFactory(context, middleware, 1);

        return middleware[0]({...context, next: nextMiddleware})
    }
    return next();
});
