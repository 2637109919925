import lang_global from './_lang_global'
import auth from "@/modules/user/auth/lang/ru/lang";
import pb from "@/modules/public/public/lang/ru/lang"
import cabinet from "@/modules/private/cabinet/lang/ru/lang"
import movie from "@/modules/movies/movie/lang/ru/lang"

export default {
    "global": lang_global,
    "auth": auth,
    "cabinet": cabinet,
    "public": pb,
    "movie": movie
}
