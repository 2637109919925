export default {
  version: 'Version: 1.0-alfa',
  watcher_time: 60,
  watcher_debug: false,
  language: 'ru',
  fallbackLocale: 'en',
  route_mode: 'history',
  // default_url: (process.env.NODE_ENV === 'production') ? 'https://api-kino.maxxidom.com' : 'http://api.mx-kinopoisk.loc'
  default_url: (process.env.NODE_ENV === 'production') ? 'https://api-kino.maxxidom.com' : 'https://api-kino.maxxidom.com'
}
