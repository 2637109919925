<template>
  <div class="mx-header">

    <div class="container__header">
      <div class="mx-header__logo">
        <img src="/images/logo.png" alt="">
      </div>
      <ul class="mx-mobile-hidden mx-header__menu">
        <li>
          <router-link :to="{name: 'movie'}" class="mx-header__menu-link" href="#">Новинки</router-link>
        </li>
        <li>
          <router-link :to="{name: 'movie.search'}" class="mx-header__menu-link" href="#">Поиск</router-link>
        </li>
        <li>
          <router-link :to="{name: 'movie.my-movie'}" class="mx-header__menu-link" href="#">Мои закладки</router-link>
        </li>
        <li>
          <router-link :to="{name: 'movie.maxxidom-movie'}" class="mx-header__menu-link" href="#">Maxxidom</router-link>
        </li>
      </ul>

      <div class="mx-mobile-show mx-header__btn_mobile-menu" @click="mobileMenu">
        <img src="/images/mobile-menu.png" alt="mobile-menu">
      </div>

    </div>

    <div :class="{'open': mobile_menu}" class="mobile_menu">
      <div class="mx-mobile-btn_close" @click="mobileMenu">
        <img src="/images/close.png" alt="mobile-menu">
      </div>
      <ul class="mobile_menu__list">
        <li>
          <router-link @click.native="mobileMenu" :to="{name: 'movie'}" class="mx-header__menu-link" href="#">Новинки</router-link>
        </li>
        <li>
          <router-link @click.native="mobileMenu" :to="{name: 'movie.search'}" class="mx-header__menu-link" href="#">Поиск</router-link>
        </li>
        <li>
          <router-link @click.native="mobileMenu" :to="{name: 'movie.my-movie'}" class="mx-header__menu-link" href="#">Мои закладки</router-link>
        </li>
          <li>
              <router-link @click.native="mobileMenu" :to="{name: 'movie.maxxidom-movie'}" class="mx-header__menu-link" href="#">Maxxidom</router-link>
          </li>
      </ul>
    </div>

  </div>
</template>

<script>

export default {
  data() {
    return {
      user: {},
      search: '',
      search_select: 'title_ru',
      search_result: [],
      mobile_menu: false
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('signOut')
    },
    mobileMenu() {
      this.mobile_menu = !this.mobile_menu
    }
  }
}
</script>

<style lang="scss" scoped>
.mx-header {
  border-bottom: 1px solid #2b2a34;

  .container__header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 1366px;
    margin: 0 auto;

    //height: 87px;
    height: 50px;
    padding: 0 30px;
  }

  &__logo {
    margin-right: 30px;

    img {
      width: 30px;
      margin-top: 7px;
      border-radius: 100px;
    }
  }

  &__menu {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;
    justify-content: center;

    li {
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }
    }

    &-link {
      color: var(--mx-white);

      &:hover, .router-link-exact-active {
        color: var(--mx-primary);
      }
    }
  }

  &__search {
    margin-left: auto;

    input {
      background-color: var(--mx-light);
      border-radius: 100px;
      padding: 8px 15px;
      width: 300px;
      border: none;
    }

    &-container {
      position: absolute;
      width: 100%;
      min-height: 200px;
      padding: 30px;
      background: var(--mx-dark);
      top: 50px;
      left: 0;
      z-index: 9999;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__cabinet {
    margin-left: 30px;
    display: flex;
    align-items: center;

    a.mx-logout {
      color: var(--mx-warning);

      &:hover {
        color: var(--mx-warning);
      }
    }
  }
}

.router-link-active {
  color: var(--mx-primary);
}



.mobile_menu {
  z-index: 999999;
  background: var(--mx-background);
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  position: absolute;
  top: - 200vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: all .250s ease;

  .close_btn {}

  &.open {
    top: 0;
  }

  &__list {
    margin-top: 30px;
    a {
      padding: 18px;
      display: block;
      width: 100%;
      text-align: center;
      font-size: 28px;
    }
  }
}

.mx-mobile-btn_close {
  position: absolute;
  cursor: pointer;
  top: 30px;
  right: 40px;

  img {
    width: 20px;
  }
}

.mx-header__btn_mobile-menu {
  cursor: pointer;
  margin-left: auto;
  width: 20px;
  display: none;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
}

.mx-header__search-container {
  display: flex;
  justify-content: center;
  align-items: center;
  .mx-article-item {
    width: 200px;
    height: 288px;
  }
}


@media (max-width: 1365px) {
  .mx-mobile-hidden {
    display: none;
  }
  .mx-mobile-show {
    display: inline-block;
  }
  .mx-header__btn_mobile-menu {
    display: flex;
  }

  .mx-header__search {
    text-align: center;
    input {
      width: 90%;
    }
  }
}

@media (max-width: 540px) {
  .mx-header__logo {
    margin-right: 0;
  }
  .mx-header__search-container {
    .mx-article-item {
      width: 150px;
      height: 215px;
    }
  }
}

</style>
