import store from '@/store'

export default function guest({next}) {
  if (store.getters['authenticated']) {
    return next({
      name: 'movie.my-movie'
    });
  }

  return next();
}
