export default {
    "logout": {
        "title": "Auslogen",
        "btn": "Auslogen"
    },
    "login": {
        "title": "Einlogen",
        "btn": "Einlogen",
        "label": {
            "email": "Email",
            "password": "Passwort"
        },
        "required": {
            "email": "E-Mail ist pflichtfeld",
            "password": "Passwort ist pflichtfeld",
        },
        "error": "E-mail oder Passwort falsch!"
    },
    "password": {
        "forgot": {
            "title": "Passwort vergessen?",
            "subtitle": "Kein Problem, das passiert schon mal.",
            "btn": {
                "forgot": "Passwort vergessen?",
                "send": "zurücksetzen",
                "login": "Zurück zur Anmeldung",
            },
            "email": "email",
            "text": {
                "title": "Wir haben Ihnen einen Link zum Zurücksetzen Ihres Passworts gesendet.",
                "description": "Wir senden Anweisungen an die angegebene E-Mail-Adresse, wenn sie mit einem Konto verknüpft ist."
            },
        },
        "reset": {
            "title": "Passwort zurücksetzen",
            "token": "Token",
            "new_password": "Neues Passwort",
            "new_password_confirmation": "Neues Passwort bestätigen",
            "btn": {
                "reset": "Absenden",
                "login": "Zurück zur Anmeldung",
            },
            "error": "Token oder Passwort ist falsch!"
        },
    },
}
