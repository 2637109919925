import * as Service from '../service/service'


export default {
    namespace: true,
    state: {
        token: null,
        auth_permissions: [],
        auth_roles: []
    },
    getters: {
        authenticated(state) {
            return state.token
        },
        auth_permissions(state) {
            return state.auth_permissions
        },
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token
        },
        SET_AUTH_PERMISSIONS(state, data) {
            state.auth_permissions = data
        },
        SET_LANGUAGE(state, language) {
            state.language = language
        },
    },
    actions: {
        /* Login */
        async signIn({dispatch}, credentials) {
            const response = await Service.login(credentials)
            dispatch('attempt', response.data.meta.token)
            return response
        },

        async passwordForgot({dispatch}, data) {
            await Service.passwordForgot(data)
        },

        async passwordReset({dispatch}, data) {
            await Service.passwordReset(data)
        },

        /* Logout */
        async signOut({dispatch}) {
            await Service.logout().then(() => {
                dispatch('reset')
                location.replace('/')
            }).catch(error => {
                switch (error.response.status) {
                    case 401:
                        dispatch('reset')
                        location.replace('/')
                        break;
                }
            })
        },

        /* Attempt */
        async attempt({commit, state, dispatch}, token) {
            if (token) {
                commit('SET_TOKEN', token)
            }
            if (!state.token) {
                return
            }

            dispatch('getMyLikeMovieIDS')

            try {
                await Service.me().then(response => {
                    dispatch('setup', response)
                })
            } catch (error) {
                commit('SET_TOKEN', null)
                commit('SET_USER', null)
            }
        },

        /* Setup */
        async setup({commit}, response) {
            commit('SET_USER', response.data)
            commit('SET_AUTH_PERMISSIONS', response.data.auth_permission)
            commit('SET_LANGUAGE', response.data.language)

            await sessionStorage.setItem('username', response.data.first_name + ' ' + response.data.last_name);
            await sessionStorage.setItem('email', response.data.email);
        },

        /* Reset */
        async reset({commit}) {
            commit('SET_TOKEN', null)
        }

    }
}

