import store from '@/store'
import axios from 'axios'

import options from "@/options"
import language from "@/scripts/mx_language"

store.subscribe((mutation) => {
  switch (mutation.type) {
    case 'SET_TOKEN':
      setToke(mutation.payload)
      break;
    case 'SET_LANGUAGE':
      setLanguage(mutation.payload)
      break;
  }
})

const setToke = function (token: string) {
  if (token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem('token', token)
  }
  else {
    axios.defaults.headers.common['Authorization'] = false;
    localStorage.removeItem('token')
  }
}

const setLanguage = function (lang: string) {
  if (lang) {
    language.set(lang);
    localStorage.setItem('user_lang', lang)
  } else {
    language.set(options.language);
  }
}
