export default {
    "logout": {
        "title": "Logout",
        "btn": "Logout"
    },
    "login": {
        "title": "Login",
        "btn": "Login",
        "label": {
            "email": "Email",
            "password": "Password"
        },
        "required": {
            "email": "E-Mail is required",
            "password": "Password is required",
        },
        "error": "E-mail or Password false!"
    },
    "password": {
        "forgot": {
            "title": "Password forgot?",
            "subtitle": "no problem",
            "btn": {
                "forgot": "Password forgot?",
                "send": "reset",
                "login": "back to Login",
            },
            "email": "Email",
            "text": {
                "title": "Our send you email",
                "description": "all description for password reset is in email"
            },
        },
        "reset": {
            "title": "Password reset",
            "token": "Token",
            "new_password": "New Password",
            "new_password_confirmation": "New Password confirmation",
            "btn": {
                "reset": "send",
                "login": "back to Login",
            },
            "error": "Token or Password is false!"
        },
    },
}
